import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import Markdown from 'react-markdown'
import getYouTubeId from '../components/utils/getYoutubeId'
import FeaturedBlogPost from '../components/FeaturedBlogPost'
import RelatedArticles from '../components/RelatedArticles'
import ShareNav from '../components/ShareNav'

const Blockquote = ({ text, isIntro = false }) => {
    const El = isIntro ? 'p' : 'blockquote'

    return (
        <El
            className={cx(
                'gradient-ocean-berry text-transparent background-clip-text text-28 leading-snug mb-40',
                {
                    'border-l-4 border-gray-100 pl-36': !isIntro,
                    'font-medium': isIntro,
                }
            )}
        >
            {text}
        </El>
    )
}

Blockquote.propTypes = {
    text: PropTypes.string.isRequired,
    isIntro: PropTypes.bool,
}

export const BlogPostPageTemplate = ({
    authorData,
    content,
    date,
    featuredImage,
    introduction,
    posts,
    slug,
    summary,
    title,
}) => (
    <>
        {authorData && (
            <FeaturedBlogPost
                post={{ authorData, date, featuredImage, summary, title }}
                isArticleEntry={true}
            />
        )}
        <article
            className={cx('relative max-w-768 mx-auto my-64 lg:my-90 px-24')}
        >
            <ShareNav
                shareLink={`${withPrefix('https://beacon.bio')}${slug}`}
                title={title}
            />
            {introduction && <Blockquote text={introduction} isIntro={true} />}
            {content &&
                content.length &&
                content.map((item, i) => {
                    const id = item.videoUrl && getYouTubeId(item.videoUrl)

                    return (
                        <div key={i} className={cx('my-40')}>
                            {item.blockquote && (
                                <Blockquote text={item.blockquote} />
                            )}

                            {id && (
                                <div
                                    className={cx(
                                        'aspect-ratio-16/9 relative w-full my-40'
                                    )}
                                >
                                    <iframe
                                        src={`https://www.youtube-nocookie.com/embed/${id}?rel=0&modestbranding=1`}
                                        tabIndex="0"
                                        frameBorder="0"
                                        title="YouTube Video"
                                        allow="accelerometer;
                                            autoplay;
                                            encrypted-media;
                                            gyroscope;
                                            picture-in-picture"
                                        allowFullScreen
                                        className={cx('absolute w-full h-full')}
                                    />
                                </div>
                            )}

                            {item.body && (
                                <Markdown
                                    source={item.body}
                                    className="rich-text"
                                />
                            )}
                        </div>
                    )
                })}
        </article>
        {posts && posts.length > 1 && <RelatedArticles posts={posts} />}
    </>
)

BlogPostPageTemplate.propTypes = {
    authorData: PropTypes.shape({
        authorName: PropTypes.string,
        authorImage: PropTypes.object,
        authorTitle: PropTypes.string,
    }),
    content: PropTypes.array,
    date: PropTypes.string,
    featuredImage: PropTypes.object,
    introduction: PropTypes.string,
    posts: PropTypes.array,
    slug: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string.isRequired,
}

const BlogPostPage = ({ data }) => {
    const { frontmatter } = data.post
    const posts = data.posts.edges
    const authors = data.authors.edges

    let author = frontmatter.author
    let entryAuthorMatch = authors.find(
        (item) => item.node.frontmatter.authorName === author
    )
    if (entryAuthorMatch)
        frontmatter.authorData = entryAuthorMatch.node.frontmatter

    posts.forEach((post) => {
        let author = post.node.frontmatter.author
        let authorMatch = authors.find(
            (item) => item.node.frontmatter.authorName === author
        )
        if (authorMatch)
            post.node.frontmatter.authorData = authorMatch.node.frontmatter
    })

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <BlogPostPageTemplate
                authorData={frontmatter.authorData}
                content={frontmatter.content}
                date={frontmatter.date}
                featuredImage={frontmatter.featuredImage}
                introduction={frontmatter.introduction}
                posts={posts}
                slug={data.post.fields.slug}
                summary={frontmatter.summary}
                title={frontmatter.title}
            />
        </Layout>
    )
}

export default BlogPostPage

BlogPostPage.propTypes = {
    data: PropTypes.shape({
        post: PropTypes.object,
        posts: PropTypes.object,
        authors: PropTypes.object,
    }),
}

export const pageQuery = graphql`
    query BlogPostByID($id: String!) {
        post: markdownRemark(id: { eq: $id }) {
            id
            fields {
                slug
            }
            frontmatter {
                title
                author
                date
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                summary
                introduction
                content {
                    blockquote
                    body
                    videoUrl
                }
            }
        }
        posts: allMarkdownRemark(
            limit: 2
            sort: { order: DESC, fields: frontmatter___date }
            filter: { frontmatter: { templateKey: { eq: "blog-post-page" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        author
                        date
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1440, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        summary
                    }
                }
            }
        }
        authors: allMarkdownRemark(
            sort: { fields: frontmatter___date }
            filter: { fileAbsolutePath: { regex: "/(_authors)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        authorName
                        authorTitle
                        authorImage {
                            childImageSharp {
                                fluid(maxWidth: 100, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
