import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import SimpleBlogPost from './SimpleBlogPost'
import ResourceBlockHeading from './ResourceBlockHeading'

const RelatedArticles = ({ posts }) => (
    <section
        className={cx(
            'bg-purple-800 text-white mb-60 pt-60 pb-100 px-24 md:px-40 xl-px-0'
        )}
    >
        <div className={cx('max-w-1264 mx-auto')}>
            <ResourceBlockHeading
                heading="Related Articles"
                viewAllText="Articles"
                viewAllSlug="/blog"
                headingLevel={3}
            />
            <div className={cx('grid lg:grid-cols-2 gap-40 mt-60 lg:mt-90')}>
                {posts.map((post) => {
                    const slug = post.node.fields.slug
                    return (
                        <div key={post.node.id}>
                            <SimpleBlogPost
                                slug={slug}
                                post={post.node.frontmatter}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    </section>
)

RelatedArticles.propTypes = {
    posts: PropTypes.array,
}

export default RelatedArticles
